import { Text } from 'components';
import Countdown from 'components/Countdown';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import WhatWillYouAchieve from './components/WhatWillYouAchieve';
import PlanIncludes from './components/PlanIncludes';
import FeaturedIn from './components/FeaturedIn';

interface MindwayCheckoutProps {}

const Container = styled.div``;

const MindwayCheckout: FC<MindwayCheckoutProps> = () => (
  <Container>
    <Countdown text="This offer is valid for: " />
    <WhatWillYouAchieve />
    <PlanIncludes />
    <FeaturedIn />
  </Container>
);

export default MindwayCheckout;
