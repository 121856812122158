import React, { FC } from 'react';

import styled from 'styled-components';

import ReactCountdown from 'react-countdown';

import Text from './Text';
import { useQuery } from 'styles/breakpoints';

interface CountdownProps {
  text?: string;
  bgColor?: string;
  textColor?: string;
  durationInSeconds?: number;
}

const Container = styled.div<{ bgColor?: string }>`
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : '#E1FCDA')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 71.25rem;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
`;

const ReactCountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const TimeText = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.011em;
  text-align: center;
`;

const CountdownUnitsContainer = styled.div<{ hasMarginLeft?: boolean }>`
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '0.625rem' : '0')};
`;

const Countdown: FC<CountdownProps> = ({
  text = 'This offer is valid for: ',
  durationInSeconds = 900,
  bgColor,
  textColor = '#000000',
}) => {
  const { isTablet } = useQuery();

  const renderer = ({
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  }) => (
    <ReactCountdownContainer>
      <CountdownUnitsContainer>
        <TimeText color="dark100" type={isTablet ? 'bodyM' : 'body'}>
          {formatted.minutes}
        </TimeText>
      </CountdownUnitsContainer>
      <TimeText color="dark100" type={isTablet ? 'bodyM' : 'body'}>
        :
      </TimeText>
      <CountdownUnitsContainer>
        <TimeText color="dark100" type={isTablet ? 'bodyM' : 'body'}>
          {formatted.seconds}
        </TimeText>
      </CountdownUnitsContainer>
    </ReactCountdownContainer>
  );

  const countdownDate = Date.now() + durationInSeconds * 1000;

  return (
    <Container bgColor={bgColor}>
      <ContentContainer>
        <Text color="dark100" type={isTablet ? 'bodyM' : 'body'}>
          {text}
        </Text>
        <ReactCountdown date={countdownDate} renderer={renderer} />
      </ContentContainer>
    </Container>
  );
};

export default Countdown;
